import { gsap } from 'gsap'
import { hasNoPrefersReducesMotion } from './prefers-reduces-motion'

let tl = gsap.timeline({
  paused: true,
  duration: 0.3,
})

const makeFocusable = (element) => element.setAttribute('tabindex', 0)
const makeUnfocusable = (element) => element.setAttribute('tabindex', -1)

const animateNav = () => {
  tl = gsap
    .timeline({
      duration: 0.3,
    })
    .to(
      '.navigation',
      {
        pointerEvents: 'initial',
      },
      '-=0.3',
    )
    .to(
      '.navigation__backdrop',
      {
        autoAlpha: 1,
      },
      '-=.4',
    )
    .to(
      '.navigation__content',
      {
        x: 0,
        duration: 0.25,
      },
      '-=.2',
    )
    .from('.navigation__item', {
      y: 8,
      opacity: 0,
      duration: 0.5,
      stagger: 0.15,
    })
    // NOTE: commented until english version is translated
    // .from(
    //   '.language__selector',
    //   {
    //     autoAlpha: 0,
    //   },
    //   '-=.2',
    // )
    .reverse()
}

const toggleNav = (el) => {
  el.classList.toggle('is-open')
  document.body.classList.toggle('navigation-is-visible')

  const isReversed = tl.reversed()

  tl.timeScale(!isReversed ? 1.5 : 1).reversed(!isReversed)

  if (document.body.classList.contains('navigation-is-visible')) {
    document.querySelectorAll('.navigation a').forEach(makeFocusable)
  } else {
    document.querySelectorAll('.navigation a').forEach(makeUnfocusable)
  }
}

const navigation = () => {
  // NOTE: Need to be triggered for the very first time to make the reverse toggle method work correctly
  if (hasNoPrefersReducesMotion) {
    animateNav()
  }

  const buttonToggleNav = document.querySelector('.js-toggle-nav')

  if (buttonToggleNav) {
    buttonToggleNav.addEventListener('click', () => toggleNav(buttonToggleNav))
    document
      .querySelector('.navigation__backdrop')
      .addEventListener('click', () => toggleNav(buttonToggleNav))

    document.querySelectorAll('.navigation__link').forEach((anchor) => {
      anchor.addEventListener('click', () => toggleNav(buttonToggleNav))
    })
  }
}

export { navigation }
