import { saveContactRequest } from './firebase'
import feedbackHTMLOK from 'bundle-text:../pages/index/feedback/feedback-ok.pug'
import feedbackHTMLKO from 'bundle-text:../pages/index/feedback/feedback-ko.pug'

// NOTE: this schema should match with the fields at the HTML page
let form = {
  data: {
    name: '',
    businessName: '',
    email: '',
    website: '',
    started: null,
    services: [],
    budget: null,
    terms: false,
  },
  errors: [],
}

const clearFormErrors = () => (form.errors = [])

const setError = (element, message) => {
  element.classList.add('has-error')
  element
    .closest('.form-control')
    .querySelector('.form-control__error').innerText = message

  const name = element.getAttribute('name')
  if (!form.errors.includes(name)) {
    form.errors.push(name)
  }
}

const setSuccess = (element) => {
  element.classList.remove('has-error')
  element
    .closest('.form-control')
    .querySelector('.form-control__error').innerText = ''

  const name = element.getAttribute('name')
  if (form.errors.includes(name)) {
    form.errors = form.errors.filter((item) => item !== name)
  }
}

function validateEmail(value) {
  const re = /\S+@\S+\.\S+/
  return re.test(value)
}

function validateText(value) {
  return value && value !== '' ? true : false
}

function validateRadio(element) {
  const name = element.getAttribute('name')
  const radioChecked = document.querySelector(`input[name="${name}"]:checked`)

  if (radioChecked) {
    form.data[name] = radioChecked.value
  }

  return radioChecked
}

function validateCheckbox(element) {
  const name = element.getAttribute('name')
  form.data[name] = []

  const checkboxChecked = document.querySelectorAll(
    `input[name="${name}"]:checked`,
  )

  if (!!checkboxChecked.length) {
    form.data[name] = [...checkboxChecked].map((service) => service.value)
  }

  return !!checkboxChecked.length
}

const validate = (element) => {
  form.data[element.getAttribute('name')] = element.value

  if (element.getAttribute('type')) {
    // TODO: get NAME instead of TYPE
    switch (element.getAttribute('type')) {
      case 'email':
        validateEmail(element.value)
          ? setSuccess(element)
          : setError(element, 'Formato de email no válido')
        break
      case 'checkbox':
        validateCheckbox(element)
          ? setSuccess(element)
          : setError(element, 'Campo requerido')
        break
      case 'radio':
        validateRadio(element)
          ? setSuccess(element)
          : setError(element, 'Campo requerido')
        break
      default:
        validateText(element.value)
          ? setSuccess(element)
          : setError(element, 'El campo no puede estar vacío')
    }
  } else {
    validateText(element.value)
      ? setSuccess(element)
      : setError(element, 'El campo no puede estar vacío')
  }
}

const validateStep = (element) => {
  const inputs = element.querySelectorAll('input, textarea')

  inputs.forEach((input) => {
    if (input.hasAttribute('required')) {
      validate(input)
    }
  })

  return form.errors.length
}

const deactivateStepsHighlighted = () =>
  document
    .querySelectorAll('.contact__step')
    .forEach((step) => step.classList.remove('is-active'))

const hightlightStep = (num) => {
  deactivateStepsHighlighted()
  document.querySelector(`#headerStep${num}`).classList.add('is-active')
}

const validateForm = () => {
  const resetForm = () => {
    contactForm.reset()
    contactForm.classList.remove('d-none')
    document.querySelector('.feedback').remove()
    navigateToFirstStep()
  }

  const navigateToLastStep = () => {
    const errors = validateStep(step1)

    if (errors === 0) {
      prev.classList.remove('d-none')
      submit.classList.remove('d-none')
      next.classList.add('d-none')
      step2.classList.remove('d-none')
      step1.classList.add('d-none')
      hightlightStep(2)
    }
  }

  const navigateToFirstStep = () => {
    prev.classList.add('d-none')
    submit.classList.add('d-none')
    next.classList.remove('d-none')
    step1.classList.remove('d-none')
    step2.classList.add('d-none')
    hightlightStep(1)
    clearFormErrors()
  }

  const contactForm = document.querySelector('#form')
  const contactFormOuterWrapper = document.querySelector('#contact .content')

  const step1 = document.querySelector('#step-1')
  const step2 = document.querySelector('#step-2')

  const prev = document.querySelector('.js-prev')
  const next = document.querySelector('.js-next')
  const submit = document.querySelector('.js-submit')

  // NOTE: Using event delegation because the buttons is appended after fire the script
  document.querySelector('#contact').addEventListener('click', (e) => {
    if (e.target.classList.contains('feedback__button')) {
      resetForm()
    }
  })

  contactForm
    .querySelectorAll('input[required], textarea[required]')
    .forEach((input) => input.addEventListener('blur', () => validate(input)))

  prev.addEventListener('click', navigateToFirstStep)
  next.addEventListener('click', navigateToLastStep)

  contactForm.addEventListener('submit', (e) => {
    e.preventDefault()

    const errors = validateStep(step2)

    if (errors === 0) {
      const data = {
        ...form.data,
        createdAt: new Date(Date.now()),
        viewed: false,
        subscribedToNewsletter: false,
      }

      saveContactRequest(data)
        .then(() => {
          deactivateStepsHighlighted()
          contactForm.classList.add('d-none')
          contactFormOuterWrapper.insertAdjacentHTML(
            'beforeend',
            feedbackHTMLOK,
          )
        })
        .catch((error) => {
          console.log(error)
          deactivateStepsHighlighted()
          contactForm.classList.add('d-none')
          contactFormOuterWrapper.insertAdjacentHTML(
            'beforeend',
            feedbackHTMLKO,
          )
        })
    }
  })
}

export { validateForm }
