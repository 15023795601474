/* 
  NOTE: 
  --------
  At this moment it is necessary because the website is contained in a single page
  so it will become unnecessary when having multiple pages
*/
const hightlightCurrentSection = () => {
  function handleIntersection(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        document
          .querySelectorAll('.navigation__link')
          .forEach((link) => link.classList.remove('is-active'))
        document
          .querySelector(
            `.navigation__link[href*="${entry.target.getAttribute('id')}"]`,
          )
          .classList.add('is-active')
      }
    })
  }

  const sections = document.querySelectorAll('.js-io')

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.2,
  }

  const observer = new IntersectionObserver(handleIntersection, options)

  sections.forEach((section) => observer.observe(section))
}

const modifyHeaderStyles = () => {
  const options = {
    root: null,
    rootMargin: '128px',
    threshold: 1,
  }

  function handleIntersection(entries) {
    const body = document.body

    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        body.classList.add('is-scrolling')
      } else {
        body.classList.remove('is-scrolling')
      }
    })
  }

  const observer = new IntersectionObserver(handleIntersection, options)

  observer.observe(document.querySelector('#about'))
}

export { hightlightCurrentSection, modifyHeaderStyles }
