import { initializeApp, getApps } from 'firebase/app'

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.API_KEY,
  authDomain: 'weco-web.firebaseapp.com',
  projectId: process.env.PROJECT_ID,
  storageBucket: 'weco-web.appspot.com',
  messagingSenderId: '115286238179',
  appId: '1:115286238179:web:478d2198df29e25bf2f6d6',
}

const firebaseApp = getApps().length === 0 && initializeApp(firebaseConfig)

export { firebaseApp }
