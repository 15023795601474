import { getFirestore, collection, doc, setDoc } from 'firebase/firestore'
import { firebaseApp } from './firebase-init'

const firestore = firebaseApp && getFirestore()

/**
 *
 * @param { Object } details Contact details
 * @param { string } details.businessName - Business name
 * @param { string } details.email - User's email
 * @param { string } details.website - [optional] User business's website
 * @param { string } details.activity - User business's activity
 * @param { boolean } details.started - Indicates if the project is already started
 * @param { string } details.technology - Project's technology if choosen
 * @param { string } details.projectDescription - Project's description
 * @param { string[] } details.services - Services the user is interested in
 * @param { string } details.otherService - If none of the services listed before fits the user's needs and he wants to type it
 * @param { string } details.budget - Budget
 * @param { string } details.terms - Terms and conditions accepted
 *
 * @param { string } details.subscribeToNewsletter - Specify if the user wants to enroll to the newsletter
 * @param { boolean } details.viewed - Indicates if the request has been checked at the dashboard (default: false)
 * @param { Date } details.createdAt - Date when the request has been created
 *
 * @returns a promise with the contact request result
 */
const saveContactRequest = async (details) => {
  const contactRequestRef = doc(collection(firestore, 'contactProject'))

  // NOTE: uncomment to test promise rejected
  // return Promise.reject(new Error('fail'))

  return await setDoc(contactRequestRef, details)
}

export { saveContactRequest }
