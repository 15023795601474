import { gsap } from 'gsap'
import { CSSRulePlugin, ScrollTrigger } from 'gsap/all'

const fx = () => {
  gsap.registerPlugin(CSSRulePlugin, ScrollTrigger)

  if (document.querySelector('.cookies')) {
    const cookiesTL = gsap.timeline({
      duration: 0.25,
      delay: 2.5,
      ease: 'circ.out',
    })

    cookiesTL
      .from('.cookies', { autoAlpha: 0, y: 48 })
      .from('.cookies__text', { opacity: 0 }, '-=.1')
      .from('.cookies__actions', { autoAlpha: 0, duration: 0 }, '-=.1')
      .from('.cookies__button', { opacity: 0, stagger: 0.15 }, '-=.1')
  }

  if (document.querySelector('.header')) {
    const line = CSSRulePlugin.getRule('.header .inner-sm::after')

    const headerTL = gsap.timeline({
      duration: 0.25,
    })

    headerTL
      .to(line, {
        width: '100%',
        ease: 'power1.out',
      })
      .from(
        '.logo path',
        {
          scale: 0.75,
          autoAlpha: 0,
          stagger: 0.1,
        },
        '-=.4',
      )
      .from('.toggle-nav', { autoAlpha: 0 }, '-=.15')
      .to(
        line,
        {
          backgroundColor: '#f3f3f3',
          ease: 'power1.out',
          duration: 1,
        },
        '+=.4',
      )
  }

  if (document.querySelector('.hero')) {
    const heroTL = gsap.timeline({
      duration: 0.3,
      delay: 0.6,
      ease: 'circ.out',
    })

    heroTL
      .from('.hero__heading', { autoAlpha: 0 })
      .from('.hero__text', { autoAlpha: 0, duration: 1 }, '-=.1')
      .from(
        '.hero__image',
        {
          x: 100,
          autoAlpha: 0,
          duration: 1.2,
        },
        '-=.6',
      )
      .from('.hero__wrap__cta', { autoAlpha: 0, duration: 0.5 }, '-=.7')
  }

  if (document.querySelector('.heading')) {
    document.querySelectorAll('.heading').forEach((heading) => {
      const headingTL = gsap.timeline({
        scrollTrigger: {
          trigger: heading,
          start: 'top +=70%',
        },
      })

      headingTL
        .from(heading.querySelectorAll('*:not(span)'), {
          y: 16,
          opacity: 0,
          stagger: 0.15,
        })
        .from(heading.querySelector('span'), { opacity: 0, scale: 1.1 }, '-=.2')
    })
  }

  if (document.querySelector('.about')) {
    document.querySelectorAll('.about-item').forEach((aboutItem) => {
      const aboutTL = gsap.timeline({
        scrollTrigger: {
          trigger: aboutItem,
          start: 'top +=80%',
        },
      })

      aboutTL.from(aboutItem.querySelectorAll('*'), {
        y: 16,
        opacity: 0,
        stagger: 0.15,
      })
    })
  }

  // TODO: fire fx before
  if (document.querySelector('.service')) {
    document.querySelectorAll('.service').forEach((service) => {
      const serviceTL = gsap.timeline({
        scrollTrigger: {
          trigger: service,
          start: 'top 95%',
          end: 'bottom center',
          toggleActions: 'play none none reset',
        },
      })

      if (service.classList.contains('odd')) {
        serviceTL.from(service, {
          x: '-3vw',
          autoAlpha: 0,
        })
      } else {
        serviceTL.from(service, {
          x: '3vw',
          autoAlpha: 0,
        })
      }

      const serviceContentTL = gsap.timeline({
        scrollTrigger: {
          trigger: service.querySelector('.service__info'),
          start: 'top 85%',
          end: 'bottom 25%',
          toggleActions: 'play none none none',
        },
      })

      serviceContentTL
        .from(service.querySelectorAll('.service__image'), {
          scale: 1.2,
          opacity: 0,
          duration: 0.2,
        })
        .from(
          service.querySelector('.service__title'),
          {
            y: 8,
            opacity: 0,
          },
          '-=0.1',
        )
        .from(
          service.querySelector('.service__text'),
          {
            y: 8,
            opacity: 0,
          },
          '-=0.1',
        )
        .from(
          service.querySelectorAll('.service__list li'),
          {
            y: 8,
            opacity: 0,
            stagger: 0.15,
          },
          '-=0.1',
        )
    })

    document.querySelectorAll('.services .path').forEach((illustration) => {
      const dashed = gsap.timeline({
        scrollTrigger: {
          trigger: illustration,
          start: 'top bottom-=100',
          end: 'bottom top+=100',
          scrub: 1,
        },
      })

      const pathLength = illustration.querySelector('path').getTotalLength()
      const mask = illustration.querySelector('mask')

      mask.style.strokeDasharray = pathLength
      mask.style.strokeDashoffset = pathLength

      dashed.to(mask, {
        strokeDashoffset: 0,
      })
    })
  }

  if (document.querySelector('.project')) {
    document.querySelectorAll('.project').forEach((project) => {
      const projectTL = gsap.timeline({
        scrollTrigger: {
          trigger: project,
          start: 'top 80%',
          toggleActions: 'play none none reverse',
        },
      })

      projectTL
        .from(project.querySelector('.project__image'), {
          opacity: 0,
          ease: 'expo.easeInOut',
        })
        .from(
          project.querySelector('.project__image'),
          {
            filter: 'grayscale(100%)',
          },
          '-=0.2',
        )
        .from(
          [
            project.querySelectorAll(
              '.project__title, .project__tags, .project__text, .button',
            ),
          ],
          {
            y: -4,
            opacity: 0,
            stagger: 0.25,
          },
          '-=0.4',
        )

      gsap.to('.wrap-image.even', {
        yPercent: -40,
        scrollTrigger: {
          trigger: '.wrap-image.even',
          start: 'top-=100 90%',
          end: 'bottom 10%',
          scrub: 1,
        },
      })

      project.querySelectorAll('.wrap-image').forEach((image) => {
        if (!image.classList.contains('even')) {
          gsap.to(image.querySelector('picture'), {
            yPercent: -10,
            scrollTrigger: {
              trigger: image,
              start: 'top-=100 90%',
              end: 'bottom 10%',
              scrub: 1,
            },
          })
        }
      })
    })
  }

  if (document.querySelector('.error')) {
    console.log('error')

    const errorTL = gsap.timeline({
      duration: 0.3,
      ease: 'circ.out',
    })

    errorTL
      .from('.error__subheading', { opacity: 0 })
      .from('.error__heading', { opacity: 0 }, '-=.2')
      .from('.error__text', { opacity: 0, duration: 1 }, '-=.1')
      .from(
        '.error__image',
        {
          opacity: 0,
          duration: 1.2,
        },
        '-=.6',
      )
      .from('.error .button', { opacity: 0, duration: 0.5 }, '-=.7')
  }

  if (document.querySelector('.footer')) {
    const footerTL = gsap.timeline({
      scrollTrigger: {
        trigger: '.footer',
        start: 'top +=75%',
      },
    })

    footerTL.from('.footer__inner > div', { opacity: 0, stagger: 0.2 })
  }
}

export { fx }
