import { fx } from './fx'
import { navigation } from './navigation'
import { hightlightCurrentSection } from './highlightCurrentSection'
import { validateForm } from './validate-form'
import { hasNoPrefersReducesMotion } from './prefers-reduces-motion'
import { cookies } from './cookies'

// Common functions to be triggered
navigation()
hightlightCurrentSection()
// IDEA: use modifyHeaderStyles()
cookies()

// Check if user has reduced motion preference enable or disable for removing or not GSAP effects
if (hasNoPrefersReducesMotion) {
  fx()
}

// Check if element exist to avoid errors
if (document.querySelector('#form')) {
  validateForm()
}
