import Analytics from 'analytics'
import googleTagManager from '@analytics/google-tag-manager'

const cookies = () => {
  const handleButtons = () => {
    acceptButton.addEventListener('click', acceptCookies)
    refuseButton.addEventListener('click', refuseCookies)
  }

  const acceptCookies = () => {
    setCookiesValue('weco-cookies-accepted')
    attachCookies()
  }

  const refuseCookies = () => {
    setCookiesValue('weco-cookies-refused')
    removeMessage()
  }

  const setCookiesValue = (optionSelected) => {
    localStorage.setItem('weco-cookies', optionSelected)
  }

  const showMessage = () => {
    element.removeAttribute('hidden')
  }

  const removeMessage = () => {
    element.remove()
  }

  const attachCookies = () => {
    Analytics({
      app: 'weco-studio',
      plugins: [
        googleTagManager({
          containerId: 'GTM-W6DFMXC',
        }),
      ],
    })
    removeMessage()
  }

  const element = document.querySelector('.cookies')
  const acceptButton = document.querySelector('.js-accept')
  const refuseButton = document.querySelector('.js-refuse')

  // Check for cookies previous answer
  if (localStorage.getItem('weco-cookies')) {
    removeMessage()

    // Check if answer was OK so add Cookies script
    if ('weco-cookies-accepted' === localStorage.getItem('weco-cookies')) {
      attachCookies()
    }
  } else {
    showMessage()
    handleButtons()
  }
}

export { cookies }
